<template>
    <div class="before">
        <FormTemplate
            @submit="validate"
            action="Подписать"
        >
            <template v-slot:desc>
                Скачайте и ознакомьтесь с договором и заявлением на включение в список застрахованных лиц. Затем нажмите кнопку "Подписать" и введите в появившееся окно код из СМС, отправленный на Ваш номер.
            </template>
            <Code
                ref="code"
                type="before"
                :getCode="getCode"
                @ready="submit"
            />
        </FormTemplate>
        <div class="before__links">
            <a
                href="/api/get-doc/offer_statement"
                download
                target="_blank"
            >
                Договор публичной оферты и заявление на включение в список застрахованных лиц.
            </a>
        </div>
    </div>
</template>

<script>
import './before.scss'

import Store from '@/store'

import { mapGetters } from 'vuex'

import getSignCode from '@/api/getSignCode'
import sendSignCode from '@/api/sendSignCode'
import sendCard from '@/api/sendCard'

import openRoute from '@/helpers/router/openRoute'

export default {
    name: 'Before',
    computed: {
        ...mapGetters({
            user: ['application/user'],
            code_hash: ['application/code']
        })
    },
    methods: {
        getCode() {
            getSignCode({
                contactData: this.user.contactData
            })
        },
        validate() {
            this.$refs.code.validate()

            this.$refs.code.isValid && this.submit()
        },
        async submit() {
            // @TODO почти нигде не обрабатываем ошибки
            const { wrongCode, next_step } = await sendSignCode({
                contactData: {
                    ...this.user.contactData,
                    code: this.$refs.code.form.code,
                    code_hash: this.code_hash
                }
            })

            if (wrongCode) {
                this.$refs.code.validate();
                this.$refs.code.formErrors.code = 'Неверный код'
                return
            }

            this.$store.commit('application/clearCode')
            this.$refs.code.resetTimer()
            if (!this.$route.params.cardInfo) {
                openRoute(next_step)
            } else {
                const card = {
                    card: this.$route.params.cardInfo.number,
                    date: this.$route.params.cardInfo.date,
                    cvv: this.$route.params.cardInfo.cvv,
                    name: this.$route.params.cardInfo.holder_name
                }

                // @TODO наверняка можно улучшить и вынести сохранение карты в lite
                const data = await sendCard(card);

                this.$router.push({
                    name: 'LoanCardSecure',
                    params: {
                        data,
                        card
                    }
                })
            }
        }
    },
    components: {
        FormTemplate: () => import('@/templates/form/Form'),
        Code: () => import('@/components/form/code/Code'),
    },
    async beforeRouteEnter(to, from, next) {
        const { passportData: { passportnumber } } = Store.getters['application/user']

        if (passportnumber) {
            if (from.name !== null) {
                await Store.dispatch('application/update')
            }

            const {
                isSigned
            } = Store.getters['application/user']

            if (isSigned) {
                Store.commit('application/load', false)
                next({
                    name: 'LoanCard'
                })
            } else {
                next()
            }
        } else {
            Store.commit('application/load', false)
            next({ name: 'LoanPassport' })
        }
    }
}
</script>